$('select.select__custom').each(function() {
	var $select 	= $(this);

	var options 	= $select.find('option');
	var first 		= $select.find('option:first-child');
	var template 	= `<div class="select__custom__dropdown dropdown">
		<div class="output" data-toggle="dropdown">${first.html()}</div>

		<ul class="dropdown-menu"</div>
	</div>`;


	$select.after(template);

	var drop = $select.siblings('.select__custom__dropdown').find('.dropdown-menu');
	var output = $select.siblings('.select__custom__dropdown').find('.output');

	options.each(function() {
		var val = $(this).attr('value');
		var text = $(this).html();

		var attr = `data-value="${val}"`;

		if(!val) {
			attr = 'data-value="" class="disabled"';
		}

		drop.append(`<li ${attr}>${text}</li>`);

	});

	var li = drop.find('li');

	li.on('select.customselect', function() {
		var val 	=  $(this).data('value');
		var text 	= $(this).text();

		li.removeClass('selected');

		$(this).addClass('selected');
		output.text(text);

		$select.val(val).trigger('change');
	});

	li.click(function() {
		$(this).trigger('select.customselect');
	});


	$select.addClass('custom-select-loaded');

	var defaultValue = $select.val();
	var intervalVerifier;


	$select.on('disconect', function() {
		cancelAnimationFrame(intervalVerifier);
	})

	verifier();

	function verifier() {
		var valorAtual = $select.val();

		if(valorAtual != defaultValue) {
			li.each(function() {

 				if($(this).data('value') == valorAtual) {
 					$(this).trigger('select.customselect');
 				}
			});

			defaultValue = valorAtual;
		}

		intervalVerifier = requestAnimationFrame(verifier);
	}
});